body {
    background-image: url('/src/images/background-quiz-app.svg');
}

main {
    margin-top: 3.5rem;
}

.heading {
    margin-bottom: 1rem;
}

.heading.quiz-starting-heading {
    margin-bottom: 1rem;
}

.quiz-container {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

/* Available quiz buttons styling */
.availableQuizButton {
    font-size: 1rem;
    border-radius: 35px;

    margin: 0rem 1rem;
    padding: 0.7rem 1rem;

    border: 1px solid #7bc9e1;
    background-color: #FFFFFF;
    color: black;
}

.availableQuizButton:active,
.availableQuizButton:hover,
.availableQuizButton:focus,
.availableQuizButton:active:focus {
    color: black;
    background-color: #E5F1F5;
    border-color: #7bc9e1;
    box-shadow: none;
}
/* End */

/* Text styling */
.username-text {
    font-weight: 400;
    margin-bottom: 1rem;
}

.waiting-page-text {
    font-size: 20px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.expected-start-time {
    font-size: 20px;
}
/* End */

/* Timer styling */
.timer-number {
    /* Gradient border */
    border: 4px solid #FFFFFF;
    border-radius: 50%;
    background-color: #C7ECF8;

    margin: auto;
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 14px 2px rgb(0 0 0 / 20%);
}

.timer-number span {
    display: block;
    margin-block-start: 0.5rem;
    font-size: 26px;
}
/* End */

/* Question container and result container styling */
.question-container, .result-container, .toplist-container {
    padding: 2rem 1rem 2rem 1rem;
    background: #FFFFFF;
    border-radius: 1.5rem;
    box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 20%);
}
/* End */

/* Answer alternativ button styling */
.answerButton, .answer-alternative-result {
    background-color: #E5F1F5;
    border-color: transparent;
    border-radius: 2.5rem;
    color: black;
    padding: 0.8rem;
    font-size: 18px;
}

.answer-alternative-result {
    border: 1px solid #DDD9D9;
}

.answerButton:active,
.answerButton:focus,
.answerButton:hover,
.answerButton:active:focus {
    color: black;
    background-color: #C7ECF8;
    border-color: #7bc9e1;
    box-shadow: none;
}
/* End */

/* Result page and top list page styling */
.scored-points {
    margin-bottom: 2rem;
}

.correctAnswerQuiz {
    background-color: #00af0d4d;
    border-color: #2F9A37;
}

.wrongAnswerQuiz {
    background-color: #ed00004a;
    border-color: #D80F1B;
}

.positionText {
    font-size: 20px;
    margin-bottom: 2rem;
}

.toplist-text {
    font-size: 20px;
    margin-bottom: 0rem;
}

.finalResultList {
    list-style-position: inside;
    padding-left: 0;
    margin-bottom: 2rem;
}

.toplist-element {
    background-color: transparent;
    border: 1px solid #DDD9D9;
    border-radius: 2.5rem;
    color: black;
    padding: 0.8rem;
    font-size: 18px;
    margin-bottom: 1rem;
}

.toplist-element:nth-child(-n+3) {
    border-color: transparent;
    background-color: #E5F1F5;
    box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 20%);
}
/* End */

/* Confetti animation styling */
.confetti-container {
    width: 100%;
    height: 100%;
}

.confetti {
    width: 1rem;
    height: 1rem;
    background-color: #f2d74e;
    position: absolute;
    left: 50%;
    animation: confetti 5s ease-in-out -2s infinite;
    transform-origin: left top;
    margin-top: -2rem;
}

.confetti:nth-child(1) {
    background-color: #f2d74e; left: 15%; animation-delay: 0;
}
.confetti:nth-child(2) {
    background-color: #95c3de; left: 20%; animation-delay: -5s;
}
.confetti:nth-child(3) {
    background-color: #ff9a91; left: 30%; animation-delay: -3s;
}
.confetti:nth-child(4) {
    background-color: #f2d74e; left: 40%; animation-delay: -2.5s;
}
.confetti:nth-child(5) {
    background-color: #95c3de; left: 50%; animation-delay: -4s;
}
.confetti:nth-child(6) {
    background-color: #ff9a91; left: 60%; animation-delay: -6s;
}
.confetti:nth-child(7) {
    background-color: #f2d74e; left: 70%; animation-delay: -1.5s;
}
.confetti:nth-child(8) {
    background-color: #95c3de; left: 80%; animation-delay: -2s;
}
.confetti:nth-child(9) {
    background-color: #ff9a91; left: 90%; animation-delay: -3.5s;
}
.confetti:nth-child(10) {
    background-color: #f2d74e; left: 95%; animation-delay: -2.5s;
}

@keyframes confetti {
    0% { transform: rotateZ(15deg) rotateY(0deg) translate(0,0); }
    25% { transform: rotateZ(5deg) rotateY(360deg) translate(-5vw,20vh); }
    50% { transform: rotateZ(15deg) rotateY(720deg) translate(5vw,60vh); }
    75% { transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw,80vh); }
    100% { transform: rotateZ(15deg) rotateY(1440deg) translate(10vw,95vh); }
}
/* End */

/* Loader */
.loader {
    padding-top: 1rem;
}

.loader-text {
    font-size: 17px;
    font-weight: 400;
}
/* End */