/* Body styling */
.admin-body {
    background-image: none;
}
/* End */

/* Alert styling */
.quizUpdateAlert{
    border-color: black;
    font-size: 1.2rem;
}
.quizUpdateAlert p{
    margin-top: 0;
    margin-bottom: 0;
}
/* End */

/* Button styling */
.question-editing-button {
    width: 100%;
    max-width: 200px;
}

.delete-question-button {
    width: 100%;
    max-width: 200px;
}

.administrate-quiz-button {
    background-color: #004875;
    border-color: #004875;
    color: white;
}

.administrate-quiz-button:active,
.administrate-quiz-button:hover,
.administrate-quiz-button:focus {
    background-color: #033453;
    border-color: #033453;
    color: white;
}

.quizOverviewButton{
    display: block;
    margin: auto;
}
/* End */

/* Section, form, modal etc styling */
#quiz-editing-page{
    text-align: left;
}

#quiz-editing-form {   
    margin-top: 3rem;
    margin-bottom: 3rem;
}

#quiz-editing-form label {
    font-weight: 700;
}

.question-editing-modal .modal-dialog{
    max-width: 90%;
}

#question-editing-form input[type="checkbox"], 
#question-adding-form input[type="checkbox"]{
    width: 2rem;
    height: 2rem;
}

.confirmationModal{
    background-color: rgba(77, 77, 77, 0.8);
}
.confirmationModal .modal-content{
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.54);
}

.infoCard{
    padding: 2rem;
    display: flex;
    background-color: #fbfbfb;
    box-shadow: 0px 0px 5px 2px rgb(168 166 166 / 20%);
}

.info-card-body svg { 
    height: 7rem;
    width: 7rem;
    margin: auto;
}  

.info-card-header{
    background-color: #fbfbfb;
    margin-bottom: 1rem;
    padding-bottom: 2rem;
}

/* End */

/* Question answers styling */
.correctAnswer,
.wrongAnswer {
    margin-bottom: 0.6rem;
}

.correctAnswer span {
    background-color: #00af0d4d;
    padding: 0.25rem;
}

.wrongAnswer span {
    background-color: #ed00004a;
    padding: 0.25rem;
}
/* End */

/* Admin timer styling */
.admin-timer{
    margin: auto;
    width: 6rem;
    height: 6rem;
}

span.admin-timer-number{
    font-size: 2.8rem;
}
/* End */

/* Admin Toplist styling */
.admin-top-list{
    padding: 1rem;
}